export default [
  {
    title: 'Valid?',
    dataIndex: 'valid',
    slots: {
      customRender: 'tags',
    },
  },
  {
    title: 'Kode Program',
    dataIndex: 'program_code',
  },
  {
    title: 'Kode Toko',
    dataIndex: 'vendor_code',
  },
  {
    title: 'Judul',
    dataIndex: 'judul',
  },
  {
    title: 'Brand',
    dataIndex: 'brand',
  },
  {
    title: 'Target Name',
    dataIndex: 'target_name',
  },
  {
    title: 'Target Q1',
    dataIndex: 'target_q1',
  },
  {
    title: 'Target Q2',
    dataIndex: 'target_q2',
  },
  {
    title: 'Target Q3',
    dataIndex: 'target_q3',
  },
  {
    title: 'Target Q4',
    dataIndex: 'target_q4',
  },
  {
    title: 'Deskripsi',
    dataIndex: 'description',
  },
  {
    title: 'Action',
    dataIndex: 'action',
  },
]
